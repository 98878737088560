import Image from 'next/image';
import Link from 'next/link';
import { SxProps, Theme } from '@mui/material/styles';
import { ReactNode } from 'react';
import { AppBar, Box, Container, Stack } from '@deltasierra/react/components/core';
import { extendSx } from '@deltasierra/react/theme';
import NavigationListMain, { NavigationListMainProps } from '../NavigationListMain';
import { NavigationListAccountProps } from '../NavigationListAccount';

export type LeftNavigationProps = NavigationListAccountProps &
    NavigationListMainProps & {
        children?: ReactNode;
        homeHref: string;
        logoImageSrc: string;
        openDropdownMobile: boolean;
        sx?: SxProps<Theme>;
        toggleOpenDropdownMobile: () => void;
    };

// TODO: Implement drawer with link and icons.
export const LeftNavigation = ({
    // AdminHref,
    // AgenciesHref,
    // AgencyHref,
    brandHref,
    buildAndScheduleHref,
    children,
    // ClientLinks,
    // ConnectHref,
    homeHref,
    // Icons,
    learnHref,
    // LocationDetailsHref,
    logoImageSrc,
    // NotificationsHref,
    // OpenDropdownMobile,
    // OpenDropdownUser,
    // OverviewHref,
    planHref,
    // ProfileHref,
    reportHref,
    // ShowAdmin,
    // ShowAgencies,
    // ShowAgency,
    showBrand,
    showBuildAndSchedule,
    showLearn,
    showPlan,
    showReport,
    showSpecialRequests,
    // SignOutHref,
    specialRequestsHref,
    sx,
    t,
    // ToggleOpenDropdownMobile,
    // ToggleOpenDropdownUser,
    // UserDropdownRef,
    // UserImageSrc,
    // UserInitials,
    // UserName,
    // ...props
}: LeftNavigationProps): JSX.Element => (
    <AppBar
        sx={extendSx(
            {
                backgroundColor: 'white',
                bottom: 0,
                height: '100%',
                left: 0,
                top: 0,
                width: 'fit-content',
            },
            sx,
        )}
    >
        <Container
            sx={{
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <Stack
                sx={{
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Box
                    component={Link}
                    href={homeHref}
                    sx={{
                        paddingBottom: '15px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        paddingTop: '15px',
                    }}
                >
                    <Image
                        alt="Digital Stack"
                        height={41}
                        priority
                        src={logoImageSrc}
                        style={{
                            maxHeight: 30,
                            width: 'auto',
                        }}
                        width={300}
                    />
                </Box>
                <Box
                    component="nav"
                    sx={{
                        color: 'black',
                    }}
                >
                    <NavigationListMain
                        brandHref={brandHref}
                        buildAndScheduleHref={buildAndScheduleHref}
                        learnHref={learnHref}
                        planHref={planHref}
                        reportHref={reportHref}
                        showBrand={showBrand}
                        showBuildAndSchedule={showBuildAndSchedule}
                        showLearn={showLearn}
                        showPlan={showPlan}
                        showReport={showReport}
                        showSpecialRequests={showSpecialRequests}
                        specialRequestsHref={specialRequestsHref}
                        t={t}
                    />
                </Box>
                {children}
            </Stack>
        </Container>
    </AppBar>
);

LeftNavigation.displayName = 'LeftNavigation';
