'use client';

import { Roles } from '@deltasierra/frontend/graphql';
import { useClickOutside } from '@deltasierra/react-hooks';
import { LeftNavigation, Navigation } from '@deltasierra/react/components/navigation';
import { useTranslations } from '@deltasierra/translations/react';
import { useState } from 'react';
import { useGlobalData, useAppWebRouter, useNavigationStateContext } from '../hooks';

const icons = {
    admin: '/icons/lock.svg',
    agencies: '/icons/building.svg',
    agency: '/icons/building.svg',
    clientLinks: '/icons/link.svg',
    connect: '/icons/rocket.svg',
    home: '/icons/externalLink.svg',
    locationDetails: '/icons/locationPin.svg',
    notifications: '/icons/envelope.svg',
    overview: '/icons/list.svg',
    profile: '/icons/user.svg',
    signOut: '/icons/logout.svg',
};

// eslint-disable-next-line max-lines-per-function
export default function NavigationContainer(props: object | undefined): JSX.Element {
    const navigationState = useNavigationStateContext();

    const [openDropdownMobile, setOpenDropdownMobile] = useState(false);
    const [openDropdownUser, setOpenDropdownUser] = useState(false);

    const AppWebRouter = useAppWebRouter();
    const {
        config: {
            features: { brandsDashboard },
        },
        me: {
            features: { leftHandNavigation },
            firstName,
            lastName,
            profilePicture,
            roles,
        },
    } = useGlobalData();

    const t = useTranslations('Navigation');

    const checkIsRole = (role: Roles): boolean => roles.includes(role);
    const isAdmin: boolean = checkIsRole(Roles.Admin);
    const isManager: boolean = checkIsRole(Roles.Manager);

    const userDropdownRef = useClickOutside<HTMLLIElement>(() => {
        setOpenDropdownUser(false);
    }, [openDropdownUser]);

    return (
        <>
            {leftHandNavigation ? (
                <LeftNavigation
                    {...props}
                    adminHref={AppWebRouter.get('/admin')}
                    agenciesHref={AppWebRouter.get('/admin/agencies')}
                    agencyHref={AppWebRouter.get('/agency/clients')}
                    brandHref={AppWebRouter.get('/brand')}
                    buildAndScheduleHref={AppWebRouter.get('/builderTemplateGallery')}
                    clientLinks={[]} // TODO - Add agency defined links for current location
                    connectHref={AppWebRouter.get('/connect')}
                    homeHref={AppWebRouter.get('/')}
                    icons={icons}
                    learnHref={AppWebRouter.get('/trythis')}
                    locationDetailsHref={AppWebRouter.get('/locationDetails')}
                    logoImageSrc="/ds_horizontal_logo_rgb.svg"
                    notificationsHref={AppWebRouter.get('/notifications')}
                    openDropdownMobile={openDropdownMobile}
                    openDropdownUser={openDropdownUser}
                    overviewHref={AppWebRouter.get('/overview')}
                    planHref={AppWebRouter.get('/planner')}
                    profileHref={AppWebRouter.get('/profile')}
                    reportHref={AppWebRouter.get('/reports')}
                    showAdmin={isAdmin}
                    showAgencies={isAdmin}
                    showAgency={isManager}
                    showBrand={isManager && brandsDashboard.enabled}
                    showBuildAndSchedule={isManager || checkIsRole(Roles.Builder)}
                    showLearn={!checkIsRole(Roles.ConnectToAccess)}
                    showPlan={isManager || isAdmin || checkIsRole(Roles.Planner)}
                    showReport={isManager || checkIsRole(Roles.Analyst)}
                    showSpecialRequests={isManager || checkIsRole(Roles.SpecialRequester)}
                    signOutHref={AppWebRouter.get('/logout')}
                    specialRequestsHref={AppWebRouter.get('/specialRequests')}
                    t={t}
                    toggleOpenDropdownMobile={() => setOpenDropdownMobile(!openDropdownMobile)}
                    toggleOpenDropdownUser={() => setOpenDropdownUser(!openDropdownUser)}
                    userDropdownRef={userDropdownRef}
                    userImageSrc={profilePicture?.signedUrl}
                    userInitials={`${firstName.trim().slice(0, 1)}${lastName.trim().slice(0, 1)}`}
                    userName={`${firstName} ${lastName}`}
                >
                    {navigationState.children}
                </LeftNavigation>
            ) : (
                <Navigation
                    {...props}
                    adminHref={AppWebRouter.get('/admin')}
                    agenciesHref={AppWebRouter.get('/admin/agencies')}
                    agencyHref={AppWebRouter.get('/agency/clients')}
                    brandHref={AppWebRouter.get('/brand')}
                    buildAndScheduleHref={AppWebRouter.get('/builderTemplateGallery')}
                    clientLinks={[]} // TODO - Add agency defined links for current location
                    connectHref={AppWebRouter.get('/connect')}
                    homeHref={AppWebRouter.get('/')}
                    icons={icons}
                    learnHref={AppWebRouter.get('/trythis')}
                    locationDetailsHref={AppWebRouter.get('/locationDetails')}
                    logoImageSrc="/ds_horizontal_logo_rgb.svg"
                    notificationsHref={AppWebRouter.get('/notifications')}
                    openDropdownMobile={openDropdownMobile}
                    openDropdownUser={openDropdownUser}
                    overviewHref={AppWebRouter.get('/overview')}
                    planHref={AppWebRouter.get('/planner')}
                    profileHref={AppWebRouter.get('/profile')}
                    reportHref={AppWebRouter.get('/reports')}
                    showAdmin={isAdmin}
                    showAgencies={isAdmin}
                    showAgency={isManager}
                    showBrand={isManager && brandsDashboard.enabled}
                    showBuildAndSchedule={isManager || checkIsRole(Roles.Builder)}
                    showLearn={!checkIsRole(Roles.ConnectToAccess)}
                    showPlan={isManager || isAdmin || checkIsRole(Roles.Planner)}
                    showReport={isManager || checkIsRole(Roles.Analyst)}
                    showSpecialRequests={isManager || checkIsRole(Roles.SpecialRequester)}
                    signOutHref={AppWebRouter.get('/logout')}
                    specialRequestsHref={AppWebRouter.get('/specialRequests')}
                    t={t}
                    toggleOpenDropdownMobile={() => setOpenDropdownMobile(!openDropdownMobile)}
                    toggleOpenDropdownUser={() => setOpenDropdownUser(!openDropdownUser)}
                    userDropdownRef={userDropdownRef}
                    userImageSrc={profilePicture?.signedUrl}
                    userInitials={`${firstName.trim().slice(0, 1)}${lastName.trim().slice(0, 1)}`}
                    userName={`${firstName} ${lastName}`}
                >
                    {navigationState.children}
                </Navigation>
            )}
        </>
    );
}

NavigationContainer.displayName = 'NavigationContainer';
